import React from 'react';
import { CheckIcon, HandThumbUpIcon, UserIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import { ArlemEvent } from './types/types';

interface EventFeedProps {
    events: ArlemEvent[];
    sessionStart: Date;
}

const icons = {
    ViewContent: UserIcon,
    AddToCart: HandThumbUpIcon,
    ProceedToCheckout: CheckIcon,
    SwipeGallery: HandThumbUpIcon,
    ViewEdgeSelector: CheckIcon,
};

const iconBackgrounds = {
    ViewContent: 'bg-gray-400',
    AddToCart: 'bg-green-500',
    ProceedToCheckout: 'bg-green-500',
    SwipeGallery: 'bg-blue-500',
    ViewEdgeSelector: 'bg-blue-500',
};

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

const EventFeed: React.FC<EventFeedProps> = ({ events, sessionStart }) => {
    return (
        <div className="flow-root">
            <ul className="-mb-8">
                {events.map((event, eventIdx) => {
                    const eventTime = moment(event.event_time);
                    const sessionStartTime = moment(sessionStart);
                    const minutesSinceStart = eventTime.diff(sessionStartTime, 'minutes');
                    const IconComponent = icons[event.event_name] || UserIcon;
                    const iconBackground = iconBackgrounds[event.event_name] || 'bg-gray-400';

                    return (
                        <li key={event.id}>
                            <div className="relative pb-8">
                                {eventIdx !== events.length - 1 ? (
                                    <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                ) : null}
                                <div className="relative flex space-x-3">
                                    <div>
                                        <span
                                            className={classNames(
                                                iconBackground,
                                                'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                            )}
                                        >
                                            <IconComponent className="h-5 w-5 text-white" aria-hidden="true" />
                                        </span>
                                    </div>
                                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                        <div>
                                            <p className="text-sm text-gray-900">
                                                <span className="font-bold">{event.event_name}</span>{' '}
                                                <span className="font-normal text-gray-500">{event.event_source_path}</span>
                                            </p>
                                        </div>
                                        <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                            <p>{minutesSinceStart} mins</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default EventFeed;