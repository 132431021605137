import React from 'react';
import moment from 'moment';
import { VisitorSession } from './types/types';
import EventFeed from './EventFeed';

interface SessionsProps {
    sessions: VisitorSession[];
}

const Sessions: React.FC<SessionsProps> = ({ sessions }) => {
    // Sort sessions in reverse chronological order based on first active time
    const sortedSessions = [...sessions].sort((a, b) => new Date(b.first_active).getTime() - new Date(a.first_active).getTime());

    return (
        <div>
            {sortedSessions.map((session, index) => {
                const sessionDuration = moment.duration(moment(session.last_active).diff(moment(session.first_active)));
                let sessionTimeActive;
                if (sessionDuration.asMinutes() < 60) {
                    sessionTimeActive = `${sessionDuration.asMinutes().toFixed(0)} minutes`;
                } else if (sessionDuration.asHours() < 24) {
                    sessionTimeActive = `${sessionDuration.asHours().toFixed(2)} hours`;
                } else {
                    sessionTimeActive = `${sessionDuration.asDays().toFixed(2)} days`;
                }

                return (
                    <div key={index} className="border rounded-lg p-4 mb-4 shadow-sm bg-gray-100 mt-10">
                        <h4 className="text-lg font-semibold">{sortedSessions.length - index}. {session.original_source} ({sessionTimeActive})</h4>
                        <div className="mt-2">
                            <div className="grid grid-cols-3 gap-4">
                                <div>
                                    <dt className="text-sm font-medium text-gray-500">Number of Events</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{session.event_log.length}</dd>
                                </div>
                                <div>
                                    <dt className="text-sm font-medium text-gray-500">Date</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{moment(session.first_active).format('MMMM Do YYYY, h:mm:ss a')}</dd>
                                </div>
                            </div>
                            <div className="mt-4 bg-white p-4 rounded">
                                <EventFeed events={session.event_log} sessionStart={session.first_active} />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default Sessions;
