import React from 'react';
import { ArlemVisitor } from './types/types';
import moment from 'moment';

interface VisitorInfoProps {
    visitor: ArlemVisitor;
}

const VisitorInfo: React.FC<VisitorInfoProps> = ({ visitor }) => {
    const duration = moment.duration(moment(visitor.lastActive).diff(moment(visitor.firstActive)));
    let timeActive;
    if (duration.asMinutes() < 60) {
        timeActive = `${duration.asMinutes().toFixed(0)} minutes`;
    } else if (duration.asHours() < 24) {
        timeActive = `${duration.asHours().toFixed(2)} hours`;
    } else {
        timeActive = `${duration.asDays().toFixed(2)} days`;
    }

    const totalEvents = visitor.sessions.reduce((acc, session) => acc + session.event_log.length, 0);

    const deviceType = [
        visitor.user_agent?.device.vendor,
        visitor.user_agent?.device.model,
        visitor.user_agent?.device.type,
    ].filter(Boolean).join(' ');

    const formattedFirstActive = moment(visitor.firstActive).format('MMMM Do YYYY, h:mm:ss a');
    const formattedLastActive = moment(visitor.lastActive).format('MMMM Do YYYY, h:mm:ss a');

    return (
        <div>
            <div className="px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Visitor Information</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Details about the visitor's activity.</p>
            </div>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">City and Region</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{visitor.ip_details?.city}, {visitor.ip_details?.region}</dd>
                    </div>
                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Original Source</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{visitor.original_source}</dd>
                    </div>
                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">IP Address</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{visitor.ip_address}</dd>
                    </div>
                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Device Type</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{deviceType}</dd>
                    </div>
                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">First Active</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{formattedFirstActive}</dd>
                    </div>
                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Last Active</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{formattedLastActive}</dd>
                    </div>
                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Time Active</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{timeActive}</dd>
                    </div>
                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Number of Sessions</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{visitor.sessionCount}</dd>
                    </div>
                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Number of Events</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{totalEvents}</dd>
                    </div>
                </dl>
            </div>
        </div>
    );
}

export default VisitorInfo;
