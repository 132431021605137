import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import './App.css';
import { ArlemVisitor } from './types/types';
import VisitorInfo from './VisitorInfo';
import Sessions from './Sessions';

function App() {
  return (
    <Router>
      <div className="container mx-auto p-4">
        <h1 className="text-xl font-bold underline mb-4">Arlem Analytics</h1>
        <Routes>
          <Route path="/visitor/:visitorId" element={<VisitorData />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

function Home() {
  return <h2 className="text-lg">Welcome! Enter a visitor ID in the URL to see their data.</h2>;
}

function VisitorData() {
  const { visitorId } = useParams();
  const [visitor, setVisitor] = useState<ArlemVisitor | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://arlem-analytics.azurewebsites.net/api/visitors/${visitorId}?code=3uXROUI5LUL2VbFIrvvema413ukn7dqEcNO-_-i-8qPgAzFudaaSJw==`);
        const result = await response.json();
        const visitorData: ArlemVisitor = result as ArlemVisitor;
        setVisitor(visitorData);
      } catch (error) {
        console.error('Error fetching the data', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [visitorId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!visitor) {
    return <p>No data found for visitor ID: {visitorId}</p>;
  }

  return (
    <div>
      <VisitorInfo visitor={visitor} />
      <Sessions sessions={visitor.sessions} />
    </div>
  );
}

export default App;
